import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Box,
    Typography,
    Avatar,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import { postRequest } from "../../Fetch/request";
import PopUp from "../Popup/PopUp";
import "./Profile.css";
import PopupForm from "./PopupForm";
import Loader from "../Loader/Loader";

const avatarOptions = [
    "/avatars/avatar1.png",
    "/avatars/avatar2.png",
    "/avatars/avatar3.png",
    "/avatars/avatar4.png",
];

const AvatarSelectionModal = ({ open, onClose, onAvatarSelect }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Select an Avatar</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {avatarOptions.map((avatar, index) => (
                        <Grid item xs={6} md={3} key={index}>
                            <Avatar
                                src={avatar}
                                alt={`Avatar ${index + 1}`}
                                sx={{
                                    width: 80,
                                    height: 80,
                                    cursor: 'pointer',
                                    border: '2px solid transparent',
                                    '&:hover': {
                                        border: '2px solid #008080',
                                    },
                                }}
                                onClick={() => onAvatarSelect(avatar)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ProfilePage = () => {
    const [userProfile, setUserProfile] = useState({});
    const [uploading, setUploading] = useState(0);
    const [avatarModalOpen, setAvatarModalOpen] = useState(false);

    const handleChange = (e) => {
        setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
    };

    const fetchData = async () => {
        const profileData = JSON.parse(localStorage.getItem("profileObject"));
        if (profileData) {
            setUserProfile({
                email: profileData.email,
                name: profileData.name,
                designation: profileData.designation,
                company: profileData.company,
                college: profileData.college,
                cgpa: profileData.cgpa,
                leetcode: profileData.leetcode,
                github: profileData.links[0].link,
                twitter: profileData.links[2].link,
                linkedin: profileData.links[1].link,
                avatar: profileData.avatar || "/avatars/default-avatar.png",
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async () => {
        setUploading(1);

        const userObject = {
            name: userProfile.name,
            email: localStorage.getItem("email"),
            designation: userProfile.designation,
            company: userProfile.company,
            cgpa: userProfile.cgpa,
            college: userProfile.college,
            avatar: userProfile.avatar,
            leetcode: userProfile.leetcode,
            links: [
                { name: "github", tag: "personal", link: userProfile.github },
                { name: "linkedin", tag: "personal", link: userProfile.linkedin },
                { name: "twitter", tag: "personal", link: userProfile.twitter },
            ],
        };

        let postResponse = await postRequest(
            process.env.REACT_APP_URL + "/user/updateUserInfo",
            userObject
        );

        setUploading(0);

        if (postResponse.status === "200") {
            localStorage.setItem("profileObject", JSON.stringify(userObject));
            fetchData();
        }
        handleClose();
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAvatarSelect = (avatar) => {
        setUserProfile({ ...userProfile, avatar });
        localStorage.setItem(
            "profileObject",
            JSON.stringify({ ...userProfile, avatar })
        );
        setAvatarModalOpen(false);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 5 }}>
            {uploading === 1 && (
                <PopUp
                    open={true}
                    handleClose={handleClose}
                    popUpTitle={"Updating"}
                    popUpMsg={"Info is Updating"}
                    popUpColor={"#008080"}
                />
            )}
            <PopupForm
                open={open}
                onClose={handleClose}
                formData={userProfile}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
            <AvatarSelectionModal
                open={avatarModalOpen}
                onClose={() => setAvatarModalOpen(false)}
                onAvatarSelect={handleAvatarSelect}
            />
            {/* Profile Card */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: '30px',
                    borderRadius: '12px',
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* Avatar and Name */}
                <Avatar
                    alt={userProfile.name}
                    src={userProfile.avatar}
                    sx={{
                        width: 120,
                        height: 120,
                        mb: 3,
                        border: '3px solid #008080',
                        cursor: 'pointer',
                    }}
                    onClick={() => setAvatarModalOpen(true)} // Open avatar selection modal
                />
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#008080' }}>
                    {userProfile.name}
                </Typography>

                {/* Social Icons */}
                <Box sx={{ mt: 2, mb: 4 }}>
                    <IconButton href={`${userProfile.linkedin}`} target="_blank">
                        <LinkedInIcon sx={{ fontSize: 30, color: '#0077B5' }} />
                    </IconButton>
                    <IconButton href={`${userProfile.twitter}`} target="_blank">
                        <TwitterIcon sx={{ fontSize: 30, color: '#1DA1F2' }} />
                    </IconButton>
                    <IconButton href={`${userProfile.github}`} target="_blank">
                        <GitHubIcon sx={{ fontSize: 30, color: '#333' }} />
                    </IconButton>
                </Box>

                {/* Profile Details */}
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            College:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.college}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            Company:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.company}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            Designation:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.designation}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            CGPA:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.cgpa}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            LeetCode:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.leetcode}
                        </Typography>
                    </Grid>
                </Grid>

                {/* Edit Profile Button */}
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#008080',
                        color: '#fff',
                        mt: 4,
                        borderRadius: '20px',
                        padding: '10px 25px',
                        '&:hover': { backgroundColor: '#006666' },
                    }}
                    onClick={handleOpen}
                >
                    Edit Profile
                </Button>
            </Box>
        </Container>
    );
};

export default ProfilePage;
