import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Chip,
    Paper,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {getRequest} from "../../Fetch/request";
import Loader from '../Loader/Loader';

const problems = [
    {
        problemId: '2779',
        title: 'Maximum Beauty of an Array After Applying Operations',
        difficultyLevel: 'Medium',
        averageSuccessRate: '57.3%',
        status: 'not_attempted',
    },
    {
        problemId: '1',
        title: 'Two Sum',
        difficultyLevel: 'Easy',
        averageSuccessRate: '54.4%',
        status: 'correct',
    },
    {
        problemId: '2',
        title: 'Add Two Numbers',
        difficultyLevel: 'Medium',
        averageSuccessRate: '44.7%',
        status: 'correct',
    },
    {
        problemId: '3',
        title: 'Longest Substring Without Repeating Characters',
        difficultyLevel: 'Medium',
        averageSuccessRate: '35.8%',
        status: 'incorrect',
    },
    {
        problemId: '4',
        title: 'Median of Two Sorted Arrays',
        difficultyLevel: 'Hard',
        averageSuccessRate: '42.2%',
        status: 'correct',
    },
    {
        problemId: '5',
        title: 'Longest Palindromic Substring',
        difficultyLevel: 'Medium',
        averageSuccessRate: '34.9%',
        status: 'incorrect',
    },
    {
        problemId: '6',
        title: 'Zigzag Conversion',
        difficultyLevel: 'Medium',
        averageSuccessRate: '50.0%',
        status: 'not_attempted',
    },
];

const ProblemDetails = () => {
    const navigate = useNavigate();
    const [problems, setProblems] = useState([]);
    const [problemLoading, setIsProblemLoading] = useState(false);

    const handleNavigate = (problemId) => {
        navigate(`/problem-statement?problemId=${problemId}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {

                const url = `${process.env.REACT_APP_URL}/api/problems?email=${localStorage.getItem('email')}`;
                setIsProblemLoading(true);
                const getResponse = await getRequest(url);
                setIsProblemLoading(false);
                if (getResponse.status === "200" && getResponse.response !== null) {
                    const transformedProblems = getResponse.response.map(problem => ({
                        problemId: problem.problemId,
                        title: problem.title,
                        difficultyLevel: problem.problemMetric.difficultyLevel,
                        averageSuccessRate: problem.problemMetric.averageSuccessRate,
                        status: problem.status || 'not_attempted' // Default to 'not_attempted' if status is not present
                    }));
                    setProblems(transformedProblems);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData(); // Call the async function
    }, []);

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            <TableContainer component={Paper} sx={{ borderRadius: '12px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                Status
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                Acceptance
                            </TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                Difficulty
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {problemLoading ? (
                        <TableRow>
                            <TableCell colSpan={4} sx={{ border: 'none' }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    minHeight: '200px' // Add minimum height for better appearance
                                }}>
                                    <Loader />
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                                problems.length > 0 && problems.map((problem) => (
                            <TableRow key={problem.problemId} hover>
                                {/* Status */}
                                <TableCell align="center">
                                    {problem.status === 'CORRECT' && (
                                        <CheckCircleIcon color="success" />
                                    )}
                                    {problem.status !== 'CORRECT' && problem.status !== 'NOT_ATTEMPTED'  && (
                                        <CancelIcon color="error" />
                                    )}
                                    {problem.status === 'NOT_ATTEMPTED' && (
                                        <HourglassEmptyIcon color="action" />
                                    )}
                                </TableCell>

                                {/* Title (Clickable Text) */}
                                <TableCell
                                    sx={{
                                        cursor: 'pointer',
                                        color: 'primary.main',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => handleNavigate(problem.problemId)}
                                >
                                    <Typography variant="body1" fontWeight="bold">
                                        {problem.title}
                                    </Typography>
                                </TableCell>

                                {/* Acceptance Rate */}
                                <TableCell align="center">
                                    <Typography variant="body2">{problem.averageSuccessRate}</Typography>
                                </TableCell>

                                {/* difficultyLevel */}
                                <TableCell align="center">
                                    <Chip
                                        label={problem.difficultyLevel}
                                        size="small"
                                        color={
                                            problem.difficultyLevel === 'Easy'
                                                ? 'success'
                                                : problem.difficultyLevel === 'Medium'
                                                    ? 'warning'
                                                    : 'error'
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        )))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default ProblemDetails;
