import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import './Editor.css';
import { getRequest, postRequest } from "../../Fetch/request";
import { useLocation } from 'react-router-dom';
import Loader from '../Loader/Loader';

const EditorComponent = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Example: Getting specific query parameter
    const problemId = queryParams.get('problemId');

    const [language, setLanguage] = useState('cpp'); // Default language is C++
    const [code, setCode] = useState('');
    const [activeTab, setActiveTab] = useState('description'); // Manage active tab state
    const [showTestCases, setShowTestCases] = useState(false); // Manage test cases visibility
    const [runSubmit, setRunSubmit] = useState("");
    const [message, setMessage] = useState("");
    const [warnings, setWarnings] = useState([]);
    const [errors, setErrors] = useState([]);
    const [errorType, setErrorType] = useState("");
    const [output, setOutput] = useState("");
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const sampleProblemDetails = {
        title: "641. Design Circular Deque",
        description: `Design your implementation of the circular double-ended queue (deque).
        Implement the MyCircularDeque class:
        - MyCircularDeque(int k): Initializes the deque with a maximum size of k.
        - boolean insertFront(int value): Adds an item at the front of Deque.
        - int getRear(): Returns the last item from Deque.
        - boolean isEmpty(): Checks if the deque is empty.
        - boolean isFull(): Checks if the deque is full.`,
        sampleTestCases: "",
        constraints: "",
        hints: ["You can use a circular array to implement this data structure efficiently."],
        sampleTestCasesFile: "",
        completeTestCasesFile: "",
        inputParsingLink: "",
        correctSolutionLink: ""
    };
    const [problemDetails, setProblemDetails] = useState(sampleProblemDetails);

    const [timer, setTimer] = useState(0); // Timer in seconds

    const [isProblemLoading, setIsProblemLoading] = useState(false);
    const [isResultLoading, setIsResultLoading] = useState(false);

    useEffect(() => {
        let interval = null;
        if (problemDetails.title) {
            interval = setInterval(() => {
                setTimer((prevTime) => prevTime + 1);
            }, 1000);
        }
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [problemDetails]);

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
        setCode(''); // Reset code when language changes
    };

    const getLanguageExtension = () => {
        switch (language) {
            case 'cpp':
                return 'cpp';
            case 'java':
                return 'java';
            case 'python':
                return 'py';
            default:
                return 'cpp';
        }
    };

    const runCode = async (testFileUrl, type) => {
        if (testFileUrl === "") return;

        const profile = JSON.parse(localStorage.getItem("profileObject"));
        const email = profile?.email; // Safely access email

        const runCodeObject = {
            language: language,
            code: code,
            testFileName: testFileUrl,
            correctSolutionFileName: problemDetails.correctSolutionFile,
            mainFileName: problemDetails.inputParsingFile,
            operation: type,
            problem: problemDetails,
            solutionViewed: false,
            hintsViewed: false,
            userId: email,
            timeSpent: timer // Add time spent
        };

        setIsResultLoading(true);
        let postResponse = await postRequest(
            process.env.REACT_APP_URL + "/api/compile",
            runCodeObject
        );
        setIsResultLoading(false);
        if( postResponse && postResponse.status === "200" ) {

            postResponse = postResponse.response;
            setRunSubmit(type);
            setErrorType(postResponse.errorType);
            setMessage(postResponse.message);
            setWarnings(postResponse.warnings);
            setErrors(postResponse.errors);
            setSubmitSuccess(postResponse.success);
            setOutput(postResponse.output);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {

                const url = `${process.env.REACT_APP_URL}/api/problems?problemId=${problemId}&email=${localStorage.getItem('email')}`;
                setIsProblemLoading(true);
                const getResponse = await getRequest(url);
                setIsProblemLoading(false);
                if (getResponse.status === "200" && getResponse.response != null && getResponse.response.length > 0) {
                    const problem = getResponse.response[0];
                    setProblemDetails(problem);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if( problemId !== null ) {
            fetchData(); // Call the async function
        }
    }, []);

    return (
        <div className="editor-container">
            {/* Problem Details Section */}
                <div className="problem-panel">
                    {isProblemLoading ? (<Loader />) : (<>
                <div className="problem-header">
                    <h2>{problemDetails.problemId}. {problemDetails.title}</h2>
                    <div className="timer" style={{"float":"right",color:"yellow"}}>
                        <h2>
                            Time: {Math.floor(timer / 60)}m {timer % 60}s
                        </h2>
                    </div>
                </div>

                {/* Tab Navigation */}
                <div className="tabs">
                    <button onClick={() => setActiveTab('description')} className={activeTab === 'description' ? 'active' : ''}>
                        Description
                    </button>
                    <button onClick={() => setActiveTab('solution')} className={activeTab === 'solution' ? 'active' : ''}>
                        Solution
                    </button>
                </div>

                {/* Tab Content */}
                <div style={{
                    display: activeTab === 'description' ? 'block' : 'none'
                }}>
                    <h5>Description</h5>
                    <div
                        dangerouslySetInnerHTML={{__html: problemDetails.description}}
                        style={{
                            fontFamily: 'Arial, sans-serif',
                            marginLeft: '5px'
                        }}
                    />

                    <h5>Test Cases</h5>
                    <pre style={{marginLeft: '10px'}}>
                        {problemDetails.sampleTestCases.replace(/\\n/g, '\n')}
                    </pre>

                    <h5>Constraints</h5>
                    <div
                        dangerouslySetInnerHTML={{__html: problemDetails.constraints}}
                        style={{
                            fontFamily: 'Arial, sans-serif',
                            lineHeight: '1.6',
                            marginLeft: '16px'
                        }}
                    />

                    <h5>Hints</h5>
                    <ul
                        style={{
                            marginLeft: '5px'
                        }}
                    >
                        {problemDetails.hints.map((hint, index) => (
                            <li key={index}>{hint}</li>
                        ))}
                    </ul>
                </div>

                <div style={{
                    display: activeTab === 'solution' ? 'block' : 'none'
                }}>
                    <h3>Solution</h3>
                    <p>This is where the solution explanation would go.</p>
                </div>
             </>)}
            </div>

            {/* Code Editor Section */}
            <div className="code-panel">
                <div className="editor-header">
                    <div className="language-selector">
                        <label htmlFor="language">Language: &nbsp;</label>
                        <select id="language" value={language}
                                onChange={handleLanguageChange}>
                            <option value="cpp">C++</option>
                            <option value="java">Java</option>
                            <option value="python">Python</option>
                        </select>
                    </div>
                    <div className="action-buttons-top">
                        <button className="run-button" onClick={() => runCode(
                            problemDetails.sampleTestCasesFile, "run")}>
                            Run
                        </button>
                        <button className="submit-button"
                                onClick={() => runCode(
                                    problemDetails.completeTestCasesFile,
                                    "submit")}>
                            Submit
                        </button>
                    </div>
                </div>

                {/* Monaco Code Editor */}
                <Editor
                    height="60vh"
                    language={getLanguageExtension()}
                    value={code}
                    theme="vs-dark"
                    onChange={(value) => setCode(value)}
                />

                {/* Results Section */}
                <div style={{ marginTop: '20px', width: '100%' }}>
                {isResultLoading ? (
                    <Loader />
                ) : (<>
                    {runSubmit !== "" && (
                        <div
                            style={{
                                backgroundColor: submitSuccess ? '#e0f7fa' : '#ffebee',
                                borderRadius: '8px',
                                padding: '20px',
                                fontFamily: 'Arial, sans-serif',
                                fontSize: '16px',
                                color: '#333',
                            }}
                        >
                            {/* Correct or Wrong Results */}
                            {submitSuccess && (output.includes('Test Case') ? (
                                <div>
                                    {/* Display Error Type as Title */}
                                    <h3 style={{ color: '#00796b' }}>
                                        {errorType.replace(/_/g, ' ').toUpperCase()}
                                    </h3>
                                    <table
                                        style={{
                                            width: '100%',
                                            borderCollapse: 'collapse',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    border: '1px solid #ccc',
                                                    padding: '8px',
                                                    background: '#b2dfdb',
                                                }}
                                            >
                                                Test Case
                                            </th>
                                            <th
                                                style={{
                                                    border: '1px solid #ccc',
                                                    padding: '8px',
                                                    background: '#b2dfdb',
                                                }}
                                            >
                                                Result
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {output.split('\n').map((line, index) => {
                                            const parts = line.split(':');
                                            if (parts.length === 2) {
                                                const [testCase, result] = parts;
                                                const isWrong = result.includes('Wrong');
                                                const isCorrect = result.includes('Correct');
                                                const isTle = result.includes("TLE")
                                                return (
                                                    <tr
                                                        key={index}
                                                        style={{
                                                            backgroundColor: isWrong
                                                                ? '#ffcccc' // Light red for wrong
                                                                : isCorrect
                                                                    ? '#ccffcc' // Light green for correct
                                                                    : isTle ? '#ffcc80' : 'inherit', // Light orange for TLE
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                border: '1px solid #ccc',
                                                                padding: '8px',
                                                                color: '#333',
                                                            }}
                                                        >
                                                            {testCase.trim()}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #ccc',
                                                                padding: '8px',
                                                                color: isWrong ? '#d32f2f' : '#388e3c',
                                                            }}
                                                        >
                                                            {result.trim()}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return null;
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null)}

                            {/* Errors or Warnings */}
                            {!submitSuccess && (
                                <div>
                                    <h3 style={{ color: '#d32f2f' }}>
                                        {errorType.replace(/_/g, ' ').toUpperCase()}
                                    </h3>
                                    {/* Render Warnings */}
                                    {warnings && warnings.length > 0 && (
                                        <div>
                                            <ul>
                                                {warnings.map((warning, index) => (
                                                    <li key={index}>
                                                        {warning}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    {/* Render Errors */}
                                    {errors && errors.length > 0 && (
                                        <div>
                                            <ul>
                                                {errors.map((error, index) => (
                                                    <li key={index}>
                                                        {error}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            )}

                        </div>
                    )}
                    </> )}
                </div>


            </div>
        </div>
    );
};

export default EditorComponent;
