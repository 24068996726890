import React, { useState, useEffect } from 'react';
import { Box, Button, Card, Container, Typography, Grid, Chip, MenuItem, Select, FormControl, InputLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './QuizApp.css';
import {getRequest} from "../../Fetch/request";
import Loader from '../Loader/Loader';
import enrichedQuestions from '../../enriched_questions.json';

// Keep only Operating System in topicsList
const topicsList = ['Operating System'];

// Update topicTags to use tags from enriched_questions.json
const topicTags = {
    'Operating System': [...new Set(enrichedQuestions.flatMap(q => q.tags))]
};

// Add these styles to your component or theme
const tagSelectStyles = {
    chip: {
        margin: '2px',
        backgroundColor: '#008080',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#006666',
        }
    },
    menuItem: {
        '&.Mui-selected': {
            backgroundColor: 'rgba(0, 128, 128, 0.1)',
            '&:hover': {
                backgroundColor: 'rgba(0, 128, 128, 0.2)',
            }
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
};

const QuizApp = () => {
    const [selectedFormat, setSelectedFormat] = useState(() => {
        return localStorage.getItem('selectedFormat') || '';
    });
    const [selectedTopic, setSelectedTopic] = useState(() => {
        return localStorage.getItem('selectedTopic') || '';
    });
    const [tags, setTags] = useState(() => {
        const saved = localStorage.getItem('selectedTags');
        return saved ? JSON.parse(saved) : [];
    });
    const [quizStarted, setQuizStarted] = useState(() => {
        return localStorage.getItem('quizStarted') === 'true';
    });
    const [flashcardStarted, setFlashcardStarted] = useState(() => {
        return localStorage.getItem('flashcardStarted') === 'true';
    });
    const [answers, setAnswers] = useState({});
    const [submitted, setSubmitted] = useState(() => {
        return localStorage.getItem('submitted') === 'true';
    });
    const [selectedAnswers, setSelectedAnswers] = useState(() => {
        const saved = localStorage.getItem('quizAnswers');
        return saved ? JSON.parse(saved) : {};
    });
    const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showReport, setShowReport] = useState(() => {
        return localStorage.getItem('showReport') === 'true';
    });
    const [isTagsOpen, setIsTagsOpen] = useState(false);

    const [quizQuestions, setQuizQuestions] = useState(() => {
        const saved = localStorage.getItem('quizQuestions');
        return saved ? JSON.parse(saved) : [];
    });

    const [flashCards, setFlashCards] = useState(() => {
        const saved = localStorage.getItem('flashCards');
        return saved ? JSON.parse(saved) : [];
    });

    // Add useEffect to save state changes
    useEffect(() => {
        localStorage.setItem('quizAnswers', JSON.stringify(selectedAnswers));
        localStorage.setItem('quizStarted', quizStarted);
        localStorage.setItem('flashcardStarted', flashcardStarted);
        localStorage.setItem('selectedTopic', selectedTopic);
        localStorage.setItem('selectedFormat', selectedFormat);
        localStorage.setItem('selectedTags', JSON.stringify(tags));
        localStorage.setItem('quizQuestions', JSON.stringify(quizQuestions));
        localStorage.setItem('submitted', submitted);
        localStorage.setItem('showReport', showReport);
    }, [selectedAnswers, quizStarted, flashcardStarted, selectedTopic, 
        selectedFormat, tags, quizQuestions, submitted, showReport]);

    // Handle starting the test
    const handleStartTest = () => {
        setIsLoading(true);
        try {
            // Filter questions based on selected tags
            let filteredQuestions = enrichedQuestions;
            
            if (tags.length > 0) {
                filteredQuestions = filteredQuestions.filter(q => 
                    tags.some(tag => q.tags.includes(tag))
                );
            }

            // Randomly select questions (e.g., 10 questions)
            const shuffled = filteredQuestions.sort(() => 0.5 - Math.random());
            const selected = shuffled.slice(0, 10);

            if (selectedFormat === "flashcard") {
                const flashcards = selected.map(q => ({
                    question: q.question,
                    answer: q.explanation
                }));
                setFlashCards(flashcards);
                localStorage.setItem('flashCards', JSON.stringify(flashcards));
                setFlashcardStarted(true);
            } else {
                const formattedQuestions = selected.map(q => ({
                    question: q.question,
                    options: Object.values(q.options),
                    correctAnswer: q.options[q.correct_option],
                    explanation: q.explanation,
                    theory: q.theory
                }));
                setQuizQuestions(formattedQuestions);
                localStorage.setItem('quizQuestions', JSON.stringify(formattedQuestions));
                setQuizStarted(true);
            }
        } catch (error) {
            console.error("Error processing questions:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle answer selection
    const handleAnswerSelect = (questionIndex, selectedOption) => {
        setSelectedAnswers(prev => {
            const newAnswers = {
                ...prev,
                [questionIndex]: selectedOption
            };
            localStorage.setItem('quizAnswers', JSON.stringify(newAnswers));
            return newAnswers;
        });
    };

    // Handle quiz submission
    const handleSubmit = () => {
        if (Object.keys(selectedAnswers).length === quizQuestions.length) {
            setSubmitted(true);
            setShowReport(true);
        }
    };

    // Handle flashcard navigation with animation
    const handleNextFlashcard = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setCurrentFlashcardIndex((prevIndex) => (prevIndex + 1) % flashCards.length);
            setIsAnimating(false);
        }, 300);
    };

    const handlePrevFlashcard = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setCurrentFlashcardIndex((prevIndex) => (prevIndex === 0 ? flashCards.length - 1 : prevIndex - 1));
            setIsAnimating(false);
        }, 300);
    };

    // Update tag options when topic changes
    const handleTopicChange = (event) => {
        const newTopic = event.target.value;
        setSelectedTopic(newTopic);
        setTags([]); // Reset tags when topic changes
    };

    // Modify the reset function to clear localStorage
    const handleReset = () => {
        setSelectedAnswers({});
        setQuizStarted(false);
        setFlashcardStarted(false);
        setSelectedTopic('');
        setSelectedFormat('');
        setTags([]);
        setSubmitted(false);
        setShowReport(false);
        setQuizQuestions([]);
        
        // Clear all localStorage items
        localStorage.removeItem('quizAnswers');
        localStorage.removeItem('quizStarted');
        localStorage.removeItem('flashcardStarted');
        localStorage.removeItem('selectedTopic');
        localStorage.removeItem('selectedFormat');
        localStorage.removeItem('selectedTags');
        localStorage.removeItem('quizQuestions');
        localStorage.removeItem('flashCards');
        localStorage.removeItem('submitted');
        localStorage.removeItem('showReport');
    };

    // Update the back button click handler
    const handleBack = () => {
        handleReset(); // Use the reset function for the back button too
    };

    // Render the quiz
    const renderQuiz = () => (
        <Box sx={{ position: 'relative' }}>
            <Grid container spacing={3}>
                {quizQuestions.map((quiz, questionIndex) => (
                    <Grid item xs={12} key={questionIndex}>
                        <Card sx={{ padding: '20px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '15px' }}>{quiz.question}</Typography>
                            <Grid container spacing={2}>
                                {quiz.options.map((option, i) => (
                                    <Grid item xs={12} sm={6} key={i}>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => handleAnswerSelect(questionIndex, option)}
                                            sx={{
                                                borderColor:
                                                    submitted && option === quiz.correctAnswer
                                                        ? 'green'
                                                        : submitted && selectedAnswers[questionIndex] === option
                                                            ? 'red'
                                                            : selectedAnswers[questionIndex] === option
                                                                ? '#008080'
                                                                : '#ccc',
                                                color:
                                                    submitted && option === quiz.correctAnswer
                                                        ? 'green'
                                                        : submitted && selectedAnswers[questionIndex] === option
                                                            ? 'red'
                                                            : selectedAnswers[questionIndex] === option
                                                                ? '#fff'
                                                                : '#000',
                                                backgroundColor:
                                                    selectedAnswers[questionIndex] === option && !submitted
                                                        ? '#008080'
                                                        : submitted && option === quiz.correctAnswer
                                                            ? 'rgba(0, 128, 0, 0.1)'
                                                            : 'transparent',
                                                '&:hover': {
                                                    backgroundColor:
                                                        submitted && option === quiz.correctAnswer
                                                            ? 'rgba(0, 128, 0, 0.1)'
                                                            : selectedAnswers[questionIndex] === option
                                                                ? '#008080'
                                                                : 'transparent',
                                                    color:
                                                        selectedAnswers[questionIndex] === option || option === quiz.correctAnswer
                                                            ? '#fff'
                                                            : '#000',
                                                },
                                            }}
                                        >
                                            {option}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#008080', color: '#fff', padding: '12px 30px', borderRadius: '10px', '&:hover': { backgroundColor: '#006666' } }}
                        onClick={handleSubmit}
                        disabled={submitted}
                    >
                        Submit Quiz
                    </Button>
                </Grid>
                {submitted && (
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold', color: '#008080' }}>
                            Quiz Completed! You answered {Object.keys(selectedAnswers).filter((index) => quizQuestions[index].correctAnswer === selectedAnswers[index]).length} out of {quizQuestions.length} questions correctly.
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    );

    // Render flashcards with flip effect and navigation
    const renderFlashcards = () => (
        <Box sx={{ position: 'relative' }}>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={6} md={6}>
                    <div className={`flip-card ${isAnimating ? 'fade-out' : 'fade-in'}`}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <span
                                    className="card-number">Card {currentFlashcardIndex
                                    + 1}/{flashCards.length}</span>
                                <div className="card-content">
                                    <Typography variant="h6" sx={{
                                        fontWeight: 'bold',
                                        marginBottom: '10px'
                                    }}>
                                        {flashCards[currentFlashcardIndex].question}
                                    </Typography>
                                </div>
                                <span
                                    className="card-hint">Hover to reveal answer</span>
                            </div>
                            <div className="flip-card-back">
                                <div className="card-content">
                                    <Typography variant="h6" sx={{
                                        fontWeight: 'bold',
                                        marginBottom: '10px',
                                    }}>
                                        {flashCards[currentFlashcardIndex].answer}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Navigation for flashcards */}
                    <div className="card-navigation">
                        <Button onClick={handlePrevFlashcard}>
                            <ArrowBackIosIcon/>
                        </Button>
                        <Button onClick={handleNextFlashcard}>
                            <ArrowForwardIosIcon />
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );

    const analyzeTheory = (theory) => {
        // Split theory into sections based on various header patterns
        const headerPattern = /(?:\n\n|\n|\b)\*\*([^*]+)\*\*(?:\n\n|\n|$)/;
        const sections = theory.split(headerPattern);
        
        // Process sections into structured format
        const structuredTheory = {};
        let currentHeading = null;
        
        for (let i = 0; i < sections.length; i++) {
            const section = sections[i].trim();
            if (i % 2 === 0 && section) {
                // This is content
                if (currentHeading) {
                    const content = section
                        .split('\n')
                        .filter(line => line.trim())  // Remove empty lines
                        .map(line => {
                            // Handle bullet points
                            if (line.trim().startsWith('*')) {
                                return {
                                    type: 'bullet',
                                    text: line.replace('*', '').trim()
                                };
                            }
                            // Handle code blocks
                            if (line.includes('```')) {
                                return {
                                    type: 'code',
                                    text: line.replace(/```/g, '').trim()
                                };
                            }
                            return {
                                type: 'text',
                                text: line.trim()
                            };
                        });
                    
                    structuredTheory[currentHeading] = content;
                }
            } else {
                // This is a heading
                currentHeading = section;
            }
        }
        
        return structuredTheory;
    };

    // Update the theory rendering part in renderReport
    const renderTheorySection = (theory) => {
        const structuredTheory = analyzeTheory(theory);

        return (
            <Box sx={{ mb: 3 }}>
                {Object.entries(structuredTheory).map(([heading, content], index) => (
                    <Box key={index} sx={{ mb: 3 }}>
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                color: '#008080', 
                                mb: 1,
                                fontWeight: 'bold'
                            }}
                        >
                            {heading}
                        </Typography>
                        
                        <Box sx={{ pl: 2 }}>
                            {content.map((item, i) => (
                                item.type === 'bullet' ? (
                                    <Typography 
                                        key={i} 
                                        sx={{ 
                                            mb: 1,
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            '&:before': {
                                                content: '"•"',
                                                marginRight: '8px',
                                                color: '#008080'
                                            }
                                        }}
                                    >
                                        {item.text}
                                    </Typography>
                                ) : (
                                    <Typography 
                                        key={i} 
                                        sx={{ mb: 1 }}
                                    >
                                        {item.text}
                                    </Typography>
                                )
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        );
    };

    const renderReport = () => (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{ mb: 3, color: '#008080', textAlign: 'center' }}>
                Quiz Report
            </Typography>
            
            <Box sx={{ mb: 3, textAlign: 'center' }}>
                <Typography variant="h6">
                    Score: {quizQuestions.filter((q, idx) => selectedAnswers[idx] === q.correctAnswer).length} 
                    / {quizQuestions.length}
                </Typography>
            </Box>

            {quizQuestions.map((quiz, index) => {
                const isCorrect = selectedAnswers[index] === quiz.correctAnswer;
                const analyzedTheory = analyzeTheory(quiz.theory || '');
                
                return (
                    <Card 
                        key={index} 
                        sx={{ 
                            mb: 3, 
                            p: 3,
                            borderLeft: isCorrect ? '5px solid green' : '5px solid red'
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Question {index + 1}: {quiz.question}
                        </Typography>

                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1" sx={{ color: isCorrect ? 'green' : 'red', fontWeight: 'bold' }}>
                                {isCorrect ? '✓ Correct' : '✗ Incorrect'}
                            </Typography>
                            <Typography>
                                Your Answer: {selectedAnswers[index]}
                            </Typography>
                            {!isCorrect && (
                                <Typography sx={{ color: 'green' }}>
                                    Correct Answer: {quiz.correctAnswer}
                                </Typography>
                            )}
                        </Box>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{ backgroundColor: '#f5f5f5' }}
                            >
                                <Typography>View Theory & Explanation</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {quiz.theory && renderTheorySection(quiz.theory)}
                                <Box>
                                    <Typography variant="h6" sx={{ color: '#008080', mb: 1 }}>
                                        Explanation:
                                    </Typography>
                                    <Typography sx={{ pl: 2 }}>
                                        {quiz.explanation}
                                    </Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                );
            })}

            <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Button
                    variant="contained"
                    onClick={handleReset}
                    sx={{
                        backgroundColor: '#008080',
                        '&:hover': { backgroundColor: '#006666' },
                        mr: 2
                    }}
                >
                    Try Another Quiz
                </Button>
            </Box>
        </Box>
    );

    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: '30px',
                padding: '30px',
                // backgroundColor: '#f9f9f9',
                // borderRadius: '15px',
                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
            }}
        >
            { isLoading ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '400px' // Prevent layout shift
                        }}>
                            <Loader />
                        </Box>
                    ) : (
                    <>
            {!quizStarted && !flashcardStarted ? (
                <Box>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#008080', marginBottom: '20px', textAlign: 'center' }}>
                        Create Your Custom Test
                    </Typography>

                    {/* Topic Input */}
                    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                        <InputLabel id="select-topic-label">Choose Topic</InputLabel>
                        <Select
                            labelId="select-topic-label"
                            value={selectedTopic}
                            onChange={handleTopicChange}
                            label="Choose Topic"
                            sx={{ backgroundColor: '#fff' }}
                        >
                            {topicsList.map((topic) => (
                                <MenuItem key={topic} value={topic}>
                                    {topic}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Tag Input */}
                    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                        <InputLabel id="select-tags-label">Tags</InputLabel>
                        <Select
                            labelId="select-tags-label"
                            multiple
                            open={isTagsOpen}
                            onOpen={() => setIsTagsOpen(true)}
                            onClose={() => setIsTagsOpen(false)}
                            value={tags}
                            onChange={(e) => {
                                const validTags = e.target.value.filter(tag => 
                                    selectedTopic && topicTags[selectedTopic].includes(tag)
                                );
                                setTags(validTags);
                            }}
                            label="Tags"
                            sx={{ backgroundColor: '#fff' }}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip 
                                            key={value} 
                                            label={value} 
                                            sx={tagSelectStyles.chip}
                                            onDelete={(e) => {
                                                e.stopPropagation();
                                                setTags(tags.filter(tag => tag !== value));
                                            }}
                                            onMouseDown={(e) => e.stopPropagation()}
                                            onClick={(e) => e.stopPropagation()}
                                            deleteIcon={
                                                <CancelIcon
                                                    onMouseDown={(e) => e.stopPropagation()}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setTags(tags.filter(tag => tag !== value));
                                                    }}
                                                />
                                            }
                                        />
                                    ))}
                                </Box>
                            )}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300,
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {selectedTopic && topicTags[selectedTopic].map((tag) => (
                                <MenuItem 
                                    key={tag} 
                                    value={tag}
                                    sx={tagSelectStyles.menuItem}
                                    selected={tags.includes(tag)}
                                >
                                    {tag}
                                    {tags.includes(tag) && (
                                        <CheckIcon sx={{ color: '#008080', ml: 1 }} fontSize="small" />
                                    )}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Format Selector */}
                    <FormControl fullWidth sx={{ marginBottom: '30px' }}>
                        <InputLabel id="select-format-label">Select Format</InputLabel>
                        <Select
                            labelId="select-format-label"
                            value={selectedFormat}
                            onChange={(e) => setSelectedFormat(e.target.value)}
                            label="Select Format"  // Ensure the label is linked
                            sx={{ backgroundColor: '#fff' }}
                        >
                            <MenuItem value="quiz">Quiz</MenuItem>
                            <MenuItem value="flashcard">Flashcards</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Start Test Button */}
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#008080',
                            color: '#fff',
                            fontWeight: 'bold',
                            padding: '12px 30px',
                            borderRadius: '10px',
                            '&:hover': { backgroundColor: '#006666' },
                            display: 'block',
                            margin: 'auto',
                        }}
                        onClick={handleStartTest}
                        disabled={!selectedTopic || !selectedFormat}
                    >
                        Start Test
                    </Button>
                </Box>
            ) : selectedFormat === 'quiz' ? (
                <Box>
                    <Box sx={{ mb: 3 }}>
                        <Button
                            onClick={handleBack}
                            sx={{
                                color: '#008080',
                                mb: 2,
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 128, 128, 0.08)'
                                }
                            }}
                            startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                        
                        <Typography 
                            variant="h4" 
                            sx={{ 
                                fontWeight: 'bold', 
                                color: '#008080', 
                                textAlign: 'center'
                            }}
                        >
                            {selectedTopic}
                        </Typography>
                        
                        {tags.length > 0 && (
                            <Box sx={{ 
                                display: 'flex', 
                                flexWrap: 'wrap', 
                                gap: 1.5, 
                                justifyContent: 'center',
                                mt: 2
                            }}>
                                {tags.map((tag) => (
                                    <Chip
                                        key={tag}
                                        label={tag}
                                        sx={{
                                            fontSize: '0.95rem',
                                            padding: '18px 12px',
                                            backgroundColor: 'rgba(0, 128, 128, 0.08)',
                                            color: '#008080',
                                            borderColor: '#008080',
                                            fontWeight: 500,
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 128, 128, 0.15)',
                                            }
                                        }}
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                    
                    {showReport ? renderReport() : renderQuiz()}
                </Box>
            ) : (
<Box>
    <Box sx={{ mb: 3 }}>
        <Button
            onClick={handleBack}
            sx={{
                color: '#008080',
                mb: 2,
                '&:hover': {
                    backgroundColor: 'rgba(0, 128, 128, 0.08)'
                }
            }}
            startIcon={<ArrowBackIcon />}
        >
            Back
        </Button>
        
        <Typography 
            variant="h4" 
            sx={{ 
                fontWeight: 'bold', 
                color: '#008080', 
                textAlign: 'center'
            }}
        >
            {selectedTopic}
        </Typography>
        
        {tags.length > 0 && (
            <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 1.5, 
                justifyContent: 'center',
                mt: 2
            }}>
                {tags.map((tag) => (
                    <Chip
                        key={tag}
                        label={tag}
                        sx={{
                            fontSize: '0.95rem',
                            padding: '18px 12px',
                            backgroundColor: 'rgba(0, 128, 128, 0.08)',
                            color: '#008080',
                            borderColor: '#008080',
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: 'rgba(0, 128, 128, 0.15)',
                            }
                        }}
                    />
                ))}
            </Box>
        )}
    </Box>
    
    {renderFlashcards()}
</Box>
            )
        }</>)}
        </Container>
    );
};

export default QuizApp;
