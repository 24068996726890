import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Typography,
    Grid,
    Chip,
    Avatar,
    IconButton
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import {getRequest} from "../../Fetch/request";
import {
    capitalizeFirstLetterOfEachWord, capitalizeWords,
    toCamelCase
} from "../../Utils/CapitalCase";
import LaunchIcon from '@mui/icons-material/Launch';
import Loader from '../Loader/Loader';

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const HomePageDashboard =  () => {
    const navigate = useNavigate();

    const [isDsaLoading, setIsDsaLoading] = useState(false);
const [isCoreLoading, setIsCoreLoading] = useState(false);


    const [dsaPerformance, setDsaPerformance] = useState({
            "totalAttempt": 0,
            "totalCorrect": 0,
            "totalWrong": 0,
            "correctSubmissionCountLastThirtyDays": [],
            "weakTopics": []
    });

    const dailyQuestionsDSA = {
        labels: Array.from({length: 30}, (_, i) => i + 1),
        datasets: [
            {
                label: 'Past 30 days',
                data: dsaPerformance.correctSubmissionCountLastThirtyDays,
                borderColor: '#008080',
                backgroundColor: 'rgba(0, 128, 128, 0.2)',
                tension: 0.4,
                segment: {
                    borderColor: (ctx) => {
                        const {p0, p1} = ctx;
                        return p1.parsed.y >= p0.parsed.y ? 'green' : 'red';
                    },
                },
            },
        ],
    };

    const [corePerformance, setCorePerformance] = useState({
        "totalAttempt": 0,
        "totalCorrect": 0,
        "totalWrong": 0,
        "correctSubmissionCountLastThirtyDays": [],
        "weakTopics": []
    });

    const dailyQuestionsCore = {
        labels: Array.from({length: 30}, (_, i) => i + 1),
        datasets: [
            {
                label: 'Past 30 days',
                data: corePerformance.correctSubmissionCountLastThirtyDays,
                borderColor: '#008080',
                backgroundColor: 'rgba(0, 128, 128, 0.2)',
                tension: 0.4,
                segment: {
                    borderColor: (ctx) => {
                        const {p0, p1} = ctx;
                        return p1.parsed.y >= p0.parsed.y ? 'green' : 'red';
                    },
                },
            },
        ],
    };

    const fetchUserInfo = async () => {
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/user/getUser?email=" + localStorage.getItem("email"));
        if( getResponse.status === "200" && getResponse["response"] != null && getResponse["response"].length > 0 ){
            let formObject = getResponse.response[0];
            localStorage.setItem("profileObject",JSON.stringify(formObject));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const email = localStorage.getItem("email") // Safely access email
                if (!email) {
                    console.error("No email found in profileObject.");
                    return;
                }

                const dsaUrl = `${process.env.REACT_APP_URL}/api/performance-metrics?email=${email}&type=DSA`;
                const coreUrl = `${process.env.REACT_APP_URL}/api/performance-metrics?email=${email}&type=CORE`;

                setIsDsaLoading(true);
                setIsCoreLoading(true);
                // Make parallel API calls
                const [dsaResponse, coreResponse] = await Promise.all([
                    getRequest(dsaUrl),
                    getRequest(coreUrl),
                ]);

                // Check responses and set state
                if (dsaResponse.status === "200" && dsaResponse.response != null) {
                    setIsDsaLoading(false);
                    setDsaPerformance(dsaResponse.response);
                }

                if (coreResponse.status === "200" && coreResponse.response != null) {
                    setIsCoreLoading(false);
                    setCorePerformance(coreResponse.response); // Assuming setCorePerformance exists
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // Call the async function
        fetchUserInfo();
    }, []);



    return (
        <Container
            maxWidth="xl"
            sx={{
                padding: '20px',
                backgroundColor: '#f5f5f5', // Light Gray background
                borderRadius: '15px',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant="h4" sx={{
                fontWeight: 'bold',
                color: '#333',
                marginBottom: '30px',
                textAlign: 'center'
            }}>
                Performance Overview
            </Typography>

            <Grid container spacing={4} justifyContent="center">
                {/* DSA Performance */}
                <Grid item xs={12} sm={6} md={6}>
                    <Card sx={{
                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '15px'
                    }}>
                        <CardContent>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'space-between',
                                marginBottom: '10px' 
                            }}>
                                <Typography variant="h6" sx={{
                                    fontWeight: 'bold',
                                    color: '#008080',
                                }}>
                                    DSA Performance
                                </Typography>
                                <IconButton 
                                    onClick={() => navigate('/overall-performance')}
                                    sx={{ 
                                        color: '#008080',
                                        padding: '4px',
                                        '&:hover': { 
                                            backgroundColor: 'rgba(0, 128, 128, 0.1)' 
                                        }
                                    }}
                                >
                                    <LaunchIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            {isDsaLoading ? (<Loader />) : (<> 
                            {/* Inline Performance Summary */}
                            <Box display="flex" justifyContent="space-around"
                                 alignItems="center"
                                 sx={{marginBottom: '15px'}}>
                                <Box textAlign="center">
                                    <Typography variant="body2" sx={{
                                        color: '#F5A623',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>
                                        Attempted
                                    </Typography>
                                    <Typography variant="h4" sx={{
                                        color: '#F5A623',
                                        fontWeight: 'bold',
                                        fontSize: '28px'
                                    }}>
                                        {dsaPerformance.totalAttempt}
                                    </Typography>
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="body2" sx={{
                                        color: '#008080',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>
                                        Correct
                                    </Typography>
                                    <Typography variant="h4" sx={{
                                        color: '#008080',
                                        fontWeight: 'bold',
                                        fontSize: '28px'
                                    }}>
                                        {dsaPerformance.totalCorrect}
                                    </Typography>
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="body2" sx={{
                                        color: '#D0021B',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>
                                        Wrong
                                    </Typography>
                                    <Typography variant="h4" sx={{
                                        color: '#D0021B',
                                        fontWeight: 'bold',
                                        fontSize: '28px'
                                    }}>
                                        {dsaPerformance.totalWrong}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* DSA Progress Graph */}
                            <Typography variant="body2" sx={{
                                marginTop: '10px',
                                color: '#555',
                                fontWeight: 'bold'
                            }}>
                                Consistency (Questions Solved per Day):
                            </Typography>
                            <Line data={dailyQuestionsDSA}
                                  options={{
                                      responsive: true,
                                      plugins: {
                                          legend: {
                                              labels: {
                                                  fontWeight: 'bold',
                                                  color: 'black',
                                                  boxWidth: 0  // This will hide the square box
                                              }
                                          }
                                      }
                                  }}
                                  height={90}
                                  width={250}/>

                            {/* Weak Areas for DSA */}
                            <Typography variant="body2" sx={{
                                marginTop: '10px',
                                color: '#555',
                                fontWeight: 'bold'
                            }}>
                                Weak DSA Topics:
                            </Typography>
                            <Box display="flex" justifyContent="start" gap={2}
                                 sx={{flexWrap: 'wrap', marginBottom: '15px'}}>
                                {dsaPerformance.weakTopics.map(
                                    (topic, index) => (
                                        <Chip
                                            key={index}
                                            label={topic}
                                            avatar={<Avatar style={{
                                                color: '#008080',
                                                backgroundColor: 'white'
                                            }}>{topic.charAt(
                                                0)}</Avatar>}
                                            clickable
                                            onClick={() => navigate(
                                                "/improve-weak-areas",
                                                {state: {
                                                    topic:topic,
                                                        type:"DSA"
                                                    }})}
                                            sx={{
                                                backgroundColor: '#008080', // Updated to main color
                                                color: 'white',
                                                fontWeight: 'bold',
                                                padding: '8px', // Reduced padding to make it less congested
                                                fontSize: '12px',
                                                '&:hover': {
                                                    backgroundColor: '#006666', // Slightly darker shade
                                                },
                                            }}
                                        />
                                    ))}
                            </Box>

                            {/* Buttons for DSA */}
                            {/*<Box display="flex" justifyContent="right"*/}
                            {/*     sx={{marginTop: '15px'}}>*/}
                            {/*    <Button*/}
                            {/*        variant="contained"*/}
                            {/*        sx={{*/}
                            {/*            backgroundColor: '#B78ED5',*/}
                            {/*            color: '#fff',*/}
                            {/*            padding: '6px 20px',*/}
                            {/*            borderRadius: '20px',*/}
                            {/*            fontWeight: 'bold',*/}
                            {/*            '&:hover': {backgroundColor: '#006666'}*/}
                            {/*        }}*/}
                            {/*        onClick={() => navigate('/session-details')}*/}
                            {/*    >*/}
                            {/*        Continue Session*/}
                            {/*    </Button>*/}
                            {/*</Box>*/}
                            </>)}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Core Subject Performance */}
                <Grid item xs={12} sm={6} md={6}>
                    <Card sx={{
                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '15px'
                    }}>
                        <CardContent>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'space-between',
                                marginBottom: '10px' 
                            }}>
                                <Typography variant="h6" sx={{
                                    fontWeight: 'bold',
                                    color: '#008080',
                                }}>
                                    Core Subjects Performance
                                </Typography>
                                <IconButton 
                                    onClick={() => navigate('/core-performance')}
                                    sx={{ 
                                        color: '#008080',
                                        padding: '4px',
                                        '&:hover': { 
                                            backgroundColor: 'rgba(0, 128, 128, 0.1)' 
                                        }
                                    }}
                                >
                                    <LaunchIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            {isDsaLoading ? (<Loader />) : (<> 
                            {/* Inline Performance Summary */}
                            <Box display="flex" justifyContent="space-around"
                                 alignItems="center"
                                 sx={{marginBottom: '15px'}}>
                                <Box textAlign="center">
                                    <Typography variant="body2" sx={{
                                        color: '#F5A623',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>
                                        Attempted
                                    </Typography>
                                    <Typography variant="h4" sx={{
                                        color: '#F5A623',
                                        fontWeight: 'bold',
                                        fontSize: '28px'
                                    }}>
                                        {corePerformance.totalAttempt}
                                    </Typography>
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="body2" sx={{
                                        color: '#008080',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>
                                        Correct
                                    </Typography>
                                    <Typography variant="h4" sx={{
                                        color: '#008080',
                                        fontWeight: 'bold',
                                        fontSize: '28px'
                                    }}>
                                        {corePerformance.totalCorrect}
                                    </Typography>
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="body2" sx={{
                                        color: '#D0021B',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>
                                        Wrong
                                    </Typography>
                                    <Typography variant="h4" sx={{
                                        color: '#D0021B',
                                        fontWeight: 'bold',
                                        fontSize: '28px'
                                    }}>
                                        {corePerformance.totalWrong}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Core Subject Progress Graph */}
                            <Typography variant="body2" sx={{
                                marginTop: '10px',
                                color: '#555',
                                fontWeight: 'bold'
                            }}>
                                Consistency (Questions Solved per Day):
                            </Typography>
                            <Line data={dailyQuestionsCore}
                                  options={{
                                      responsive: true,
                                      plugins: {
                                          legend: {
                                              labels: {
                                                  fontWeight: 'bold',
                                                  color: 'black',
                                                  boxWidth: 0  // This will hide the square box
                                              }
                                          }
                                      }
                                  }}
                                  height={90}
                                  width={250}/>

                            {/* Weak Areas for Core Subjects */}
                            <Typography variant="body2" sx={{
                                marginTop: '10px',
                                color: '#555',
                                fontWeight: 'bold'
                            }}>
                                Weak Core Topics:
                            </Typography>
                            <Box display="flex" justifyContent="start" gap={2}
                                 sx={{flexWrap: 'wrap', marginBottom: '15px'}}>
                                {corePerformance.weakTopics.map(
                                    (topic, index) => (
                                        <Chip
                                            key={index}
                                            label={topic.description}
                                            avatar={<Avatar style={{
                                                color: '#008080',
                                                backgroundColor: 'white'
                                            }}>{topic.description.charAt(
                                                0)}</Avatar>}
                                            clickable
                                            onClick={() => navigate(
                                                "/improve-weak-areas",
                                                {state: topic})}
                                            sx={{
                                                backgroundColor: '#008080', // Updated to main color
                                                color: 'white',
                                                fontWeight: 'bold',
                                                padding: '8px', // Reduced padding to make it less congested
                                                fontSize: '12px',
                                                '&:hover': {
                                                    backgroundColor: '#006666', // Slightly darker shade
                                                },
                                            }}
                                        />
                                    ))}
                            </Box>

                            {/* Buttons for Core Subjects */}
                            {/*<Box display="flex" justifyContent="right"*/}
                            {/*     sx={{marginTop: '15px'}}>*/}
                            {/*    <Button*/}
                            {/*        variant="contained"*/}
                            {/*        sx={{*/}
                            {/*            backgroundColor: '#B78ED5',*/}
                            {/*            color: '#fff',*/}
                            {/*            padding: '6px 20px',*/}
                            {/*            borderRadius: '20px',*/}
                            {/*            fontWeight: 'bold',*/}
                            {/*            '&:hover': {backgroundColor: '#006666'}*/}
                            {/*        }}*/}
                            {/*        onClick={() => navigate('/core')}*/}
                            {/*    >*/}
                            {/*        Start a quiz*/}
                            {/*    </Button>*/}
                            {/*</Box>*/}
                            </>)}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Resume Builder Option */}
                <Grid item xs={12} sm={6} md={6}>
                    <Card sx={{
                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '15px'
                    }}>
                        <CardContent>
                            <Typography variant="h6" sx={{
                                fontWeight: 'bold',
                                color: '#008080',
                                marginBottom: '10px'
                            }}>
                                Resume Builder
                            </Typography>
                            <Typography variant="body2" sx={{
                                marginBottom: '10px',
                                color: '#555'
                            }}>
                                Create a professional resume using our builder
                                tool.
                            </Typography>
                            <Box display="flex" justifyContent="right">
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#008080',
                                        color: '#fff',
                                        padding: '6px 20px',
                                        borderRadius: '20px',
                                        fontWeight: 'bold',
                                        '&:hover': {backgroundColor: '#006666'}
                                    }}
                                    onClick={() => navigate('/resume-builder')}
                                >
                                    Build Resume
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Personalized Project Creation */}
                <Grid item xs={12} sm={6} md={6}>
                    <Card sx={{
                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '15px'
                    }}>
                        <CardContent>
                            <Typography variant="h6" sx={{
                                fontWeight: 'bold',
                                color: '#008080',
                                marginBottom: '10px'
                            }}>
                                Personalized Project Creation
                            </Typography>
                            <Typography variant="body2" sx={{
                                marginBottom: '10px',
                                color: '#555'
                            }}>
                                Explore projects for backend and tech-wise
                                development.
                            </Typography>
                            <Box display="flex" justifyContent="right">
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#008080',
                                        color: '#fff',
                                        padding: '6px 20px',
                                        borderRadius: '20px',
                                        fontWeight: 'bold',
                                        '&:hover': {backgroundColor: '#006666'}
                                    }}
                                    onClick={() => navigate('/applications')}
                                >
                                    Explore Projects
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HomePageDashboard;
